  $(->
    $id = $('body').attr('id')
    $class = $('body').attr('class')
  
    pageData = JSON.parse(localStorage.getItem('recent_ogp'))
  
    pageData.forEach((page, index) ->
      if page.img.indexOf('http') isnt 0
        pageData[index].img = 'https://www.theclinic.jp/' + page.img
    )
  
    if pageData.length > 0
  
      pageTemplate = $('#recent-pages-template').html();
  
      pageRenderData = {
        'pages': pageData
      }
  
      console.log(pageRenderData)
  
      rendered = Mustache.render(pageTemplate, pageRenderData);
      $('#recent-pages').get(0).insertAdjacentHTML('afterbegin', rendered)
  
      $('.p-recent__list.js-slide').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3
      });
  )
  